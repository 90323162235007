// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}focus/ad/batchAll`

// 新增
const createURL = `${API_CONFIG.butlerBaseURL}focus/info`
// 编辑
const editURL = `${API_CONFIG.butlerBaseURL}focus/ad/closeAll`
// 查询详情
const queryURL = `${API_CONFIG.butlerBaseURL}focus/ad/getAdFocusInfo`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=startUpImg`

// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
// 检测园区是否可以跳转
const checkSkipCommunityURL = `${API_CONFIG.shopBaseURL}skipLifeShu/checkSkipCommunity`
// 发布项目管理列表
const getBatchManageCommunityListURL = `${API_CONFIG.butlerBaseURL}focus/ad/getBatchManageCommunityList`
// 发布项目关闭
const closeByFocusAdIdsURL = `${API_CONFIG.butlerBaseURL}focus/ad/closeByFocusAdIds`

export {
  getListURL,
  createURL,
  editURL,
  queryURL,
  uploadURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL,
  checkSkipCommunityURL,
  getBatchManageCommunityListURL,
  closeByFocusAdIdsURL
}
