<template>
  <div class="CardMgrList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
      :selectable="selectable"
    >
      <template #headerSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <v-select2 label="发布项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams" :width="160"></v-select2>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.status === 1" text="关闭" type="text" @click="closeCard(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量关闭" :disabled="scope.selectedData.ids.length === 0" @click="batch(scope.selectedData)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getBatchManageCommunityListURL, closeByFocusAdIdsURL } from './api'
import { setStatusOps } from './map'
import {
  communityParams, regionParams
} from 'common/select2Params'
export default {
  name: 'CardMgrList',
  data () {
    return {
      searchUrl: `${getBatchManageCommunityListURL}?id=${this.$route.query.id}`,
      statusOps: setStatusOps(1),
      communityParams,
      regionParams,
      searchParams: {
        communityId: '',
        regionId: '',
        status: undefined
      },
      headers: [
        {
          prop: 'communityName',
          label: '发布项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'statusName',
          label: '状态'
        }
      ],
      putHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }
    }
  },
  methods: {
    selectable (row, index) {
      return row.status === 1
    },
    updateStatus (ids) {
      let postData = {
        focusInfoIdList: ids
      }
      this.$axios.put(closeByFocusAdIdsURL, postData).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message.success('操作成功')
        }
      })
    },
    async batch (selected) {
      let isOk = await this.$confirm('确定执行该操作？')
      isOk && this.updateStatus(selected.ids)
    },
    goBack () {
      this.$router.go(-1)
    },
    edit (row) {
      this.$router.push({
        name: 'cardMgrForm',
        query: {
          id: row.id
        }
      })
    },
    async closeCard (row) {
      let isOk = await this.$confirm('确定关闭该项目的广告？')
      isOk && this.$axios.put(closeByFocusAdIdsURL, {
        focusInfoIdList: [ row.id ]
      }).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message.success('操作成功')
        }
      })
    }
  }
}
</script>
