import {
  generateMapByOpts,
  mapHelper
} from 'common/utils'

// 状态
const status = [{
  text: '正常',
  value: 1
}, {
  text: '关闭',
  value: 0
}]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 所属App
const type = [{
  text: '用户端APP',
  value: 12
}, {
  text: '物管端APP',
  value: 14
}]

const {
  map: typeMap,
  setOps: setTypeOps
} = mapHelper.setMap(type)
// 跳转类型
const focusType = [{
  text: '无',
  value: 5
}, {
  text: '至详情页',
  value: 1
}, {
  text: '至功能页',
  value: 2
}, {
  text: '至外部链接',
  value: 3
}]

const {
  map: focusTypeMap,
  setOps: setFocusTypeOps
} = mapHelper.setMap(focusType)

// 项目状态
const communityStatus = [{
  text: '正常',
  value: 0
},
{
  text: '关闭',
  value: 1
}
]

const {
  map: communityStatusMap,
  setOps: setCommunityStatusOps
} = mapHelper.setMap(communityStatus)
// 区域类型
const regionTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '公司',
    value: 0
  },
  // {
  //   text: '园区公司分公司',
  //   value: 1
  // },
  {
    text: '管理项目组',
    value: 2
  }
]

const regionTypeMap = generateMapByOpts(regionTypeOps)
// jump-go校验参数映射
const jumpGoCodeMap = {
  storeCode: 'storeIdList', // 门店
  productCode: 'productIdList', // 商品
  secKillCode: 'secKillIdList', // 秒杀
  discountTopicCode: 'discountTopicId', // 促销专题
  knowledgeInfoCode: 'knowledgeInfoIdList', // 资讯
  postsCode: 'tableIdList', // 帖子
  groupCode: 'groupIdList', // 社群
  socialActivityCode: 'socialActivityIdList', // 社区活动
  surveyCode: 'questionIdList', // 问卷调查
  selfRuleCode: 'selfRuleIdList', // 自治规约
  autonomyVoteCode: 'questionNaireIdList', // 自治表决
  workRoomCode: 'workRoomIdList' // 社区工作室
}
export {
  statusMap,
  setStatusOps,
  typeMap,
  setTypeOps,
  focusTypeMap,
  setFocusTypeOps,
  communityStatusMap,
  setCommunityStatusOps,
  regionTypeOps,
  regionTypeMap,
  jumpGoCodeMap
}
